import React, { useState } from 'react';

import {images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email:'', message:''});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const {name, value } = e.target;
  
    setFormData({ ...formData,[name]:value});
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    }

    client.create(contact)
    .then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    })

  }

  return (
    <>
    <h2 className='head-text'> Grab some tea and let us chat </h2>
    
    <div className='app__footer-cards'>
      <div className='app__footer-card'>
        <img src ={images.email} alt="email"/>
        <a href='mailto:anghelutaadrian89@gmail.com' className='p-text'>anghelutaadrian89@gmail.com</a>
      </div>

      <div className='app__footer-card'>
        <img src ={images.mobile} alt="mobile"/>
        <a href='tel:0614541668' className='p-text'> +FR 0614541668</a>
      </div>
    </div>


{ !isFormSubmitted ? 
      <div className='app__footer-form app__flex'>
          <div className='app__flex'>
            <input className='p-tex' type="text" placeholder='Your Name' name='username' value={ username } onChange={ handleChangeInput } />
          </div>

          <div className='app__flex'>
            <input className='p-tex' type="email" placeholder='Your Email' name='email' value={ email } onChange={ handleChangeInput } />
          </div>

          <div>
              <textarea 
              className='p-text'
              placeholder='Your Message'
              value={message}
                name='message'
                onChange={handleChangeInput}
                id="" 
                cols="30" 
                rows="10"></textarea>
          </div>

          <button type='button' className='p-text' onClick={handleSubmit}> {loading ?'Sending' : 'Send Message' }</button>
      </div>

      : <div>
        <h3 className='head-text'> Thank you for being in touch!</h3>
      </div>
      }



    </>
  )
}



export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
